import React from "react";
import { Card, Image } from "react-bootstrap";

function Certification(props) {
  const { title, certificationImage, publicPath } = props.certification;

  const currentLocation = window.location.href;

  console.log(window.location.href);

  return (
    <Card
      style={{
        background: "rgba(255, 255, 255, 0.06   )",
        borderRadius: "15px",
      }}
      className=" text-white p-2 h-100"
    >
      <Card.Header
        as="h4"
        className="px-0 py-2 my-2 d-flex justify-content-center"
      >
        <Card.Title className="text-center my-1">{title}</Card.Title>
      </Card.Header>
      <Card.Body className="my-2 d-flex justify-content-center align-items-center">
        <a
          href={`${currentLocation}/${publicPath}`}
          className="block"
          style={{ textDecoration: "none" }}
        >
          <Image src={certificationImage} rounded fluid />
        </a>
      </Card.Body>
    </Card>
  );
}

Certification.defaultProps = {
  title: "",
};

export default Certification;
