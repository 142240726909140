import React, { useContext } from 'react'
import { SocialIcon } from 'react-social-icons'
import UserContext from '../../context/UserContext'

function Footer() {
    const {socialMediaLinks} = useContext(UserContext)
    return (
        <footer
            className='text-center'
            style={ {borderTop: '1px solid white'} }
        >
            <div className='p-5'>
                <section className='mb-5'>
					<span className='p-1'>
						<SocialIcon
                            fgColor='white'
                            url={ socialMediaLinks.facebook }
                        />
					</span>
                    <span className='p-1'>
						<SocialIcon
                            fgColor='white'
                            url={ socialMediaLinks.instagram }
                        />
					</span>
                    <span className='p-1'>
						<SocialIcon
                            fgColor='white'
                            url={ socialMediaLinks.linkedin }
                        />
					</span>
                    <span className='p-1'>
						<SocialIcon
                            bgColor='black'
                            fgColor='white'
                            url={ socialMediaLinks.github }
                        />
					</span>
                </section>
                <div className='text-center text-white '>
                    © { new Date().getFullYear() } Copyright
                </div>
            </div>
        </footer>
    )
}

export default Footer
