import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import UserContext from "../../../../context/UserContext";
import Certification from "./Certification";

function ListCertifications() {
  const { certifications } = useContext(UserContext);

  return (
    <>
      <h3 className="text-white my-5 text-center">
        Certifications <br />
      </h3>
      <Row className="d-flex flow-row justify-content-center">
        {certifications.map((certification) => (
          <Col key={certification.id} md={6} sm={6} lg={4} className="my-3">
            <Certification certification={certification} />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default ListCertifications;
