import Education from './Education'
import { useContext } from 'react'
import UserContext from '../../../../context/UserContext'

function ListEducations() {
    const {education} = useContext(UserContext)
    return (
        <>
            { education.map((education) => (
                <Education
                    key={ education.id }
                    orgName={ education.organization }
                    orgPicture={ education.organizationPic }
                    orgWebLink={ education.webLink }
                    duration={ education.duration }
                    degree={ education.degree }
                />
            )) }
        </>
    )
}

export default ListEducations
