import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import ListEducations from './components/ListEducations'
import '../../shared/shared-styles/animate.css'

function EducationPage() {
    useEffect(() => {
        document.title = 'Education'
    }, [])
    return (
        <Container
            style={ {minHeight: '90vh'} }
            className='d-flex flex-column w-100 mt-5 align-items-center animate'
        >
            <h3 className='text-white mb-5 text-center'>
                My Education <br/>
            </h3>
            <ListEducations/>
            <br/>
        </Container>
    )
}

export default EducationPage
