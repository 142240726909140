import { Container } from 'react-bootstrap'

function NotFound() {
    return (
        <Container className='d-flex flex-column justify-content-center align-items-center '
                   style={ {minHeight: '70vh'} }>
            <h3 className="text-white">
                Oops!
            </h3>
            <h3 className="text-white">
                This page could be found
            </h3>
        </Container>
    )

}

export default NotFound