import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import UserContext from '../../../../context/UserContext'
import WorkExperience from './WorkExperience'

function ListWorkExperience() {
    const {workExperiences} = useContext(UserContext)

    return (
        <>
            <h3 className='text-white my-5 text-center'>
                Work Experience <br/>
            </h3>
            <Row className='d-flex flow-row justify-content-center'>
                { workExperiences.map(workExperience =>
                    <Col key={ workExperience.id } md={ 6 } sm={ 6 } lg={ 4 }
                         className='my-3'>
                        <WorkExperience workExperience={ workExperience }/>
                    </Col>
                ) }
            </Row>
        </>
    )
}

export default ListWorkExperience
