import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ListWorkExperience from './components/ListWorkExperience'

function WorkExperiencePage() {
    useEffect(() => {
        document.title = 'Work Experience'
    }, [])
    return (
        <Container
            style={ {minHeight: '90vh', color: 'white'} }
            className='d-flex flex-row justify-content-center mb-5'
        >
            <Row>
                <Col>
                    <ListWorkExperience/>
                </Col>
            </Row>
        </Container>
    )
}


export default WorkExperiencePage
