import {createContext} from "react";
import seeuLogo from "../assets/university-logo.jpg";
import ykcLogo from "../assets/high-school-logo.jpg";
import ibrLogo from "../assets/elementary-school-logo.jpg";
import uniBaselLogo from "../assets/unibasel-logo.png";
import displayPicture from "../assets/display-picture-2.jpeg";
import inetingLogo from "../assets/ineting-logo.jpeg";
import webFaderLogo from "../assets/webFader-logo.png";
import inspire11Logo from "../assets/inspire11Logo.jpeg";
import googleClassroomLogo from "../assets/Google_Classroom_Logo.png";
import pythonLogo from "../assets/python-logo.png";
import laravelLogo from "../assets/laravel-logo.png";
import reactLogo from "../assets/react-logo.png";
import phpLogo from "../assets/php-logo.png";
import jsLogo from "../assets/js-logo.png";
import baselHackAward from "../assets/certifications/BaselHack Award.jpeg";
import degree from "../assets/certifications/Bachelor's degree.jpeg";
import learningDocker from "../assets/certifications/Docker.jpeg";
import microservices from "../assets/certifications/Microservices.jpeg";
import reactTypeScript from "../assets/certifications/React TypeScript.jpeg";
import dotNetAuth from "../assets/certifications/DotNetAuth.jpeg";
import dotNetAPI from "../assets/certifications/DotNetAPI.jpeg";

const UserContext = createContext();

export const UserProvider = ({children}) => {
    const header = {
        brandName: "EA",
    };

    const homepage = {
        keySkills: [
            "PHP",
            "Javascript",
            "C#",
            "Python",
            "Node.js",
            "React",
            "Redux",
            "TypeScript",
            "ASP.NET Core",
            "Laravel",
            "MySQL",
            "NGINX",
            "Azure",
            "Git",
            "Docker",
            "Redis",
            "HTML",
            "CSS/SCSS",
            "REST",
            "Microservices",
            "Postman",
            "JIRA",
            "Unit Testing",
        ],
        heading:
            "Hi! I'm Enes, a detail-oriented, full stack\n" +
            "                        software engineer.",
        cvPath: "cv/CV.pdf",
        displayPicture: displayPicture,
    };

    const education = [
        {
            id: 1,
            organization: "University of Basel",
            degree: `M.Sc. Computer Science`,
            duration: "2023 - Current",
            organizationPic: uniBaselLogo,
            webLink: "https://www.unibas.ch/de",
        },
        {
            id: 2,
            organization: "South East European University",
            degree: `Bachelor's Degree, Computer Science, 9.3/10`,
            duration: "2017 - 2020",
            organizationPic: seeuLogo,
            webLink: "https://www.seeu.edu.mk",
        },
        {
            id: 3,
            organization: "Yahya Kemal College",
            degree: `Certificate for Completed Secondary Education, 5/5`,
            duration: "2013 - 2017",
            organizationPic: ykcLogo,
            webLink: "https://www.ykc.edu.mk",
        },
        {
            id: 4,
            organization: "Ibrahim Temo",
            degree: `Certificate for Completed Elementary Education, 5/5`,
            duration: "2004 - 2013",
            organizationPic: ibrLogo,
            webLink: "https://www.shfkibrahimtemo.edu.mk/",
        },
    ];

    const projects = [
        {
            id: 1,
            image: googleClassroomLogo,
            title: "inventorx-backend",
            description:
                "REST API to manage and organize company resources, clients, projects, and tasks with utmost efficiency and precision.",
            repoLink: "https://github.com/ealili/inventorx-backend",
            stackLogo: laravelLogo,
        },
        {
            id: 2,
            title: "inventorx-frontend",
            description:
                "React application to manage and organize company resources, clients, projects, and tasks with utmost efficiency and precision powered by a robust REST API in Laravel.",
            repoLink: "https://github.com/ealili/inventorx-frontend",
            stackLogo: reactLogo,
        },
        {
            id: 3,
            title: "Laravel Chat App",
            description:
                "Group chat application built on Vue with a Laravel backend. ",
            repoLink: "https://github.com/ealili/laravel-chat",
            stackLogo: laravelLogo,
        },
        {
            id: 4,
            title: "AutoClassroom API",
            description:
                "API that takes a csv file and connect to Google Classroom API in order to generate " +
                "classrooms in a fast way.",
            repoLink: "https://gitlab.com/ealili/autoclassroom-api",
            stackLogo: pythonLogo,
        },
        {
            id: 5,
            title: "MrTechSpecs",
            description:
                "A front-end web application where visitors can see phone specifications.",
            repoLink: "https://github.com/ealili/mrtechspecs",
            stackLogo: reactLogo,
        },
        {
            id: 6,
            title: "MrTechSpecs API",
            description: "REST api built in PHP for the MrTechSpecs project.",
            repoLink: "https://github.com/ealili/api",
            stackLogo: phpLogo,
        },
        {
            id: 7,
            title: "ThreeJS Screensaver",
            description:
                "Screensaver done using ThreeJS during a university project.",
            repoLink: "https://github.com/ealili/threejs-screen-saver",
            stackLogo: jsLogo,
        },
    ];

    const socialMediaLinks = {
        linkedin: "https://www.linkedin.com/in/ealili",
        github: "https://www.github.com/ealili",
        instagram: "https://www.instagram.com/eneesalili",
        facebook: "https://www.facebook.com/eness.alilii",
    };

    const workExperiences = [
        {
            id: 1,
            companyName: "Ineting",
            companyURL: "http://ineting.net/",
            companyLogo: inetingLogo,
            jobTitle: "Backend Developer",
            jobDescription: `Developed backend systems using Laravel framework to create efficient and scalable microservices for the company. 
                Implemented CRUD operations within the microservices, ensuring data management and manipulation.
                Implemented role-based access control (RBAC) to manage user permissions and ensure data integrity and security. 
                Utilized Laravel's job queuing system to optimize background tasks, improving system performance and' +
                responsiveness. Assisted in the deployment process by configuring and managing Docker containers.`,
            duration: "1month",
            position: "Intern",
        },
        {
            id: 2,
            companyName: "Webfader",
            companyURL: "https://www.webfader.com",
            companyLogo: webFaderLogo,
            jobTitle: "Software Developer Internship",
            jobDescription: `Developed a dynamic web application from the ground up using PHP, focusing on creating a robust, scalable, and efficient system tailored to the company's specific needs.
                Designed user-friendly interfaces and intuitive user experiences, emphasizing ease of use and accessibility for various stakeholders within the organization.
                Implemented best practices in coding standards, security, and performance optimization, contributing to a reliable and high-performing web application.`,
            duration: "1month",
            position: "Intern",
        },
        {
            id: 3,
            companyName: "Inspire11",
            companyURL: "https://www.inspire11.com",
            companyLogo: inspire11Logo,
            jobTitle: "Software Developer",
            jobDescription: `Developed a backend application using Flask in Python, creating a seamless integration between the company's data files and Google Classroom API' +
                Implemented a secure login system, restricting API access to a specific organization, ensuring data privacy and security' +
                Integrated the application with Google Classroom API, ensuring secure and reliable communication to manage classroom creation, assignments, and user interactions' +
                Collaborated with teams to gather requirements, refine features, and provide technical support.`,
            duration: "1month",
            position: "Intern",
        },
    ];

    const certifications = [
        {
            id: 1,
            title: "Bachelor's Degree",
            certificationImage: degree,
            publicPath: "Bachelor's degree.jpeg",
        },
        {
            id: 2,
            title: "BaselHack Award",
            certificationImage: baselHackAward,
            publicPath: "BaselHack Award.jpeg",
        },
        {
            id: 3,
            title: "Learning Docker",
            certificationImage: learningDocker,
            publicPath: "Docker.jpeg",
        },
        {
            id: 4,
            title: "Microservices Foundations",
            certificationImage: microservices,
            publicPath: "Microservices.jpeg",
        },
        {
            id: 5,
            title: "React: Using TypeScript",
            certificationImage: reactTypeScript,
            publicPath: "React TypeScript.jpeg",
        },
        {
            id: 6,
            title: "ASP.NET Core: Token-Based Authentication",
            certificationImage: dotNetAuth,
            publicPath: "DotNetAuth.jpeg",
        },
        {
            id: 7,
            title: "ASP.NET CORE WEP APIs",
            certificationImage: dotNetAPI,
            publicPath: "DotNetAPI.jpeg",
        },
    ];

    return (
        <UserContext.Provider
            value={{
                homepage,
                education,
                socialMediaLinks,
                displayPicture,
                projects,
                workExperiences,
                header,
                certifications,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
