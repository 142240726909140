import React from 'react'
import {Card, Image} from 'react-bootstrap'

function WorkExperience(props) {
    const {
        companyName,
        companyLogo,
        jobDescription,
        companyURL,
        jobTitle,
        duration,
        position
    } = props.workExperience
    return (
        <Card
            style={{
                background: 'rgba(255, 255, 255, 0.06   )',
                borderRadius: '15px',
            }}
            className=' text-white p-2 h-100'
        >
            <Card.Header as='h4' className='px-0 py-2 my-2 d-flex justify-content-center'>
                {/*<Image*/}
                {/*    width={ 120 }*/}
                {/*    src={ image }/>*/}
                <span className={'px-2 pb-2'}>

                <Image
                    width={30}
                    src={companyLogo} rounded/>
                </span>
                <Card.Title className='text-center my-1'>{companyName}</Card.Title>
            </Card.Header>
            <Card.Body className='my-2'>
                <Card.Title>{jobTitle}</Card.Title>
                <hr/>
                <Card.Text>{jobDescription}</Card.Text>
                <a href={companyURL} target={'_blank'}> <Card.Text>{companyURL}</Card.Text></a>
                <br/>
            </Card.Body>
        </Card>
    )
}

WorkExperience.defaultProps = {
    companyBrad: '',
    companyName: 'Company Name',
    jobDescription: 'Job description',
    companyURL: 'Company URL'
}

export default WorkExperience

