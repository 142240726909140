import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListCertifications from "./components/ListCertifications";

const CertificationsPage = () => {
  useEffect(() => {
    document.title = "Certifications";
  }, []);
  return (
    <Container
      style={{ minHeight: "90vh", color: "white" }}
      className="d-flex flex-row justify-content-center mb-5"
    >
      <Row>
        <Col>
          <ListCertifications />
        </Col>
      </Row>
    </Container>
  );
};

export default CertificationsPage;
