import React, { useContext, useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./homepage.css";
import UserContext from "../../../context/UserContext";

function HomePage() {
  const { homepage } = useContext(UserContext);
  const { keySkills, heading, cvPath, displayPicture } = homepage;

  useEffect(() => {
    document.title = "Home";
  }, []);

  return (
    <Container
      style={{ minHeight: "90vh" }}
      className="d-flex flex-column justify-content-center text-white animate"
    >
      <Row>
        <Col
          className="d-flex flex-column align-items-center mt-4 px-4"
          lg={5}
          md={5}
          sm={12}
          xs={12}
        >
          <Image
            width="300"
            height="auto"
            src={displayPicture}
            roundedCircle
            className="img-fluid"
          />
        </Col>
        <Col lg={7} md={7} sm={12} xs={12} className="my-5">
          <h1 className="text-center my-5">{heading}</h1>
          {/*<div className="d-flex justify-content-center mt-5">*/}
          {/*  <a*/}
          {/*    href={cvPath}*/}
          {/*    className="block"*/}
          {/*    style={{ textDecoration: "none" }}*/}
          {/*  >*/}
          {/*    <Button className="px-5 py-2 fs-4" variant="light">*/}
          {/*      /!* <span*/}
			{/*						className='fs-5 px-3 py-2'*/}
			{/*						style={{*/}
			{/*							border: '1px solid black',*/}
			{/*							borderRadius: '50%',*/}
			{/*						}}*/}
			{/*					>*/}
			{/*						&#10515;*/}
			{/*					</span>{' '} 	&nbsp; *!/*/}
          {/*      Preview CV*/}
          {/*    </Button>*/}
          {/*  </a>*/}
          {/*</div>*/}
        </Col>
      </Row>
      <Row style={{ paddingTop: "10%" }}>
        <h5 className="text-center">
          Key skills &rarr;
          {keySkills.map((keySkill) => (
            <span className="block" key={keySkill}>
              &nbsp; {keySkill} &nbsp; |{" "}
            </span>
          ))}
        </h5>
      </Row>
    </Container>
  );
}

export default HomePage;
