import React, { useContext } from 'react'
import Project from './Project'
import { Col, Row } from 'react-bootstrap'
import UserContext from '../../../../context/UserContext'

function ListProjects() {
    const {projects} = useContext(UserContext)

    return (
        <>
            <h3 className='text-white my-5 text-center'>
                My Projects <br/>
            </h3>
            <Row className='d-flex flow-row justify-content-center'>
                { projects.map(project =>
                    <Col key={ project.id } md={ 6 } sm={ 6 } lg={ 4 } className='my-3'>
                        <Project project={ project }/>
                    </Col>
                ) }
            </Row>
        </>
    )
}

export default ListProjects
