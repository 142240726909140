import React from "react";
import { Card, Image, Row, Col } from "react-bootstrap";

function Education({ orgName, orgPicture, orgWebLink, degree, duration }) {
  return (
    <Card
      style={{
        background: "rgba(255, 255, 255, 0.06   )",
        borderRadius: "25px",
      }}
      className="w-100 text-white mb-4 p-4"
    >
      <Card.Header as="h4">
        <Row className="my-2">
          <Col
            sm={12}
            md={1}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Image
              width="70"
              height="auto"
              src={orgPicture}
              roundedCircle
              className="img-fluid my-2"
            />
          </Col>
          <Col
            sm={12}
            md={11}
            className="d-flex flex-column justify-content-center align-items-xs-center align-items-sm-center align-items-md-start"
          >
            <div>{orgName}</div>
            <div>
              <a
                href={orgWebLink}
                style={{ fontSize: "16px" }}
                target={"_blank"}
              >
                {orgWebLink}
              </a>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Card.Title>{degree}</Card.Title>
        <Card.Text>{duration}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Education;
