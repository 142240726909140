import Header from "./components/shared/header/Header";
import Footer from "./components/shared/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/home/HomePage";
import ProjectsPage from "./components/pages/projects/ProjectsPage";
import ContactPage from "./components/pages/contact/ContactPage";
import EducationPage from "./components/pages/education/EducationPage";
import { UserProvider } from "./context/UserContext";
import NotFound from "./components/NotFound";
import WorkExperiencePage from "./components/pages/work-experience/WorkExperiencePage";
import CertificationsPage from "./components/pages/certifications/CertificationsPage";

function App() {
  return (
    <UserProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/education" element={<EducationPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          {/*<Route path='/contact' element={ <ContactPage/> }/>*/}
          <Route path="/work-experience" element={<WorkExperiencePage />} />
          <Route path="/certifications" element={<CertificationsPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </UserProvider>
  );
}

export default App;
