import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ListProjects from './components/ListProjects'

function Projects() {
    useEffect(() => {
        document.title = 'Projects'
    }, [])
    return (
        <Container
            style={ {minHeight: '90vh', color: 'white'} }
            className='d-flex flex-row justify-content-center mb-5'
        >
            <Row>
                <Col>
                    <ListProjects/>
                </Col>
            </Row>
        </Container>
    )
}


export default Projects
